*{
    font-family: 'Oxygen', sans-serif;
    scroll-behavior: smooth;
}
.Slidercont{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    color: rgb(239, 218, 218);
    width: 90%;
    height: 100%;
}
.fondo{
    background-image: url('/src/imgs/fondo.jpeg');
    background-size: cover;
}
.cont{
    background-color: rgba(0, 0, 0, 0.247);
    height: 80vh;
    margin-top: 3em;
    margin-bottom: 3em;
}
.cont img{
    object-fit:cover;
    height: 100%;
    width: 100%;
    border-radius: 0.2em;
}


.tarjeta1{
    background-image: url(/src/imgs/azulejo.jpg);
    background-size: cover;
    height: 100%;
    border-radius: 0.25em;
}
.tarjeta2{
    background-image: url(/src/imgs/reforma.jpg);
    background-size: cover;
    border-radius: 0.25em;
}
.tarjeta3{
    background-image: url(/src/imgs/suelo.jpg);
    background-size: cover;
    border-radius: 0.25em;
}